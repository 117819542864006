import axios, * as others from "axios";

export const apiURL =
  "https://ravitechworld.com/LiveTesting/MRProducts/BackEndAPI/"; //Testing API

// export const apiURL = "https://mrproducts.in/BackEndAPI/"; //Live API

const axiosInstance = axios.create({
  baseURL: apiURL,
});

export function fetchproductdetails(data) {
  return axiosInstance.post("productdetail.php", data);
}
export function fetchdistributordetails(data) {
  return axiosInstance.post("distributordetail.php", data);
}

export function fetchcontactus(data) {
  return axiosInstance.post("contactusdetail.php", data);
}
