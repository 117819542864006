import React, { Component } from "react";
import Image2 from "./images/bg1.jpg";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Button, Grid } from "@mui/material";
import "./Product.css";
import img2 from "./images/a3.jpg";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { fetchproductdetails, apiURL } from "./API";
import { withRouter } from "../HOC/withRouter";
import { withTranslation } from "react-i18next";

class Product extends Component {
  state = {
    selectedCategory: "All",
    dataLoadStatus: false,
    product: [],
  };

  componentDidMount() {
    Aos.init({
      duration: 1000,
    });
    this.onFormLoad();
  }

  componentWillReceiveProps(nextProps) {
    // This method is called when props are updated.
    // this.checkLanguageChange();
    this.onFormLoad();
  }

  // checkLanguageChange = () => {
  //   // Check if language in localStorage has changed
  //   this.onFormLoad();
  // };

  onFormLoad = () => {
    console.log("call on form load");

    let dataLoadStatus = false;
    this.setState({ dataLoadStatus }, () => {
      let reqData = {
        Op: "ProductList",
      };
      fetchproductdetails(reqData)
        .then((res) => {
          let product = [];
          // console.log('(localStorage.getItem("i18nextLng") == "en"',localStorage.getItem("i18nextLng") )
          if (localStorage.getItem("i18nextLng") == "eng") {
            res.data.ProductList.filter((row) => {
              if (row.vac_language == "Eng") {
                product.push(row);
              }
            });
          } else if (localStorage.getItem("i18nextLng") == "ગુજ") {
            res.data.ProductList.filter((row) => {
              if (row.vac_language == "Guj") {
                product.push(row);
              }
            });
          }

          this.setState({
            dataLoadStatus: true,
            product,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log("error", "Unkonwn Error occured in onFormLoad. " + error);
        });
    });
  };

  handleCategoryClick = (category) => {
    this.setState({ selectedCategory: category });
  };

  // item.vac_producttitle,
  // item.dec_productmrp,
  // item.vac_productsubtitle,
  // item.vac_productimage,
  // item.vac_productdetails,
  // item.vac_netweight

  navigateToMoreInfo = (
    vac_producttitle,
    dec_productmrp,
    vac_productsubtitle,
    vac_productcategory,
    vac_productimage,
    vac_productdetails,
    vac_netweight,
    vac_productdosedetails
  ) => {
    // console.log("vac_productcategory", vac_productcategory);
    console.log(
      "title, price, description",
      vac_producttitle,
      dec_productmrp,
      vac_productsubtitle,
      vac_productcategory,
      vac_productimage,
      vac_productdetails,
      vac_netweight,
      vac_productdosedetails
    );
    this.props.navigateTo("Productinfo", {
      vac_producttitle: vac_producttitle,
      dec_productmrp: dec_productmrp,
      vac_productsubtitle: vac_productsubtitle,
      vac_productcategory: vac_productcategory,
      vac_productimage: vac_productimage,
      vac_productdetails: vac_productdetails,
      vac_netweight: vac_netweight,
      vac_productdosedetails: vac_productdosedetails,
    });
  };

  getAllTitlesAndDescriptions = () => {
    const allData = [];
    for (const category in this.state.categoriesData) {
      if (Array.isArray(this.state.categoriesData[category])) {
        allData.push(...this.state.categoriesData[category]);
      }
    }
    return allData;
  };

  render() {
    const { selectedCategory } = this.state;
    const { t } = this.props;

    return (
      <div className="Product">
        <CardMedia
          sx={{ height: "300px", marginTop: "-115px" }}
          src={Image2}
          component="img"
          alt="banner"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          marginTop={3}
          spacing={2}
        >
          <Grid item>
            <button onClick={() => this.handleCategoryClick(t("All"))}>
              {t("All")}
            </button>
          </Grid>
          <Grid item>
            <button onClick={() => this.handleCategoryClick(t("Fertilizer"))}>
              {t("Fertilizer")}
            </button>
          </Grid>
          <Grid item>
            <button onClick={() => this.handleCategoryClick(t("Pesticides"))}>
              {t("Organic Pesticides")}
            </button>
          </Grid>
          <Grid item>
            <button onClick={() => this.handleCategoryClick(t("Seeds"))}>
              {t("Seeds")}
            </button>
          </Grid>
        </Grid>
        <Grid>
          <h1
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "30px",
            }}
          >
            {selectedCategory == "All"
              ? t("All")
              : selectedCategory == "Fertilizer"
              ? t("Fertilizer")
              : selectedCategory == "Pesticides"
              ? t("Organic Pesticides")
              : selectedCategory == "Seeds"
              ? t("Seeds")
              : selectedCategory}
          </h1>
          <Container>
            <Box
              display="flex"
              justifyContent="space-evenly"
              flexWrap="wrap"
              gap={4}
              alignItems="center"
              className="viewmore"
            >
              {selectedCategory === "All" || selectedCategory === "બધા"
                ? this.state.product.map((item, index) => (
                    <Card
                      // data-aos="zoom-out-up"
                      data-aos="zoom-out"
                      // data-aos="zoom-in"
                      sx={{
                        width: 300,
                        height: "auto",
                        background: "#96d1818c",
                        borderRadius: "12px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "280px",
                          height: "300px",
                          backgroundColor: "white",
                          display: "block",
                          margin: "8px auto",
                          borderRadius: "10px",
                        }}
                      >
                        <CardMedia
                          sx={{
                            height: 300,
                            width: 200,
                            display: "block",
                            margin: "4px auto",
                          }}
                          // image={apiURL + item.vac_productimage}/
                          image={
                            item.vac_productimage.includes(apiURL)
                              ? item.vac_productimage
                              : apiURL + item.vac_productimage
                          }
                        />
                      </div>
                      <CardContent
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.vac_producttitle}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          style={{ fontWeight: "bold" }}
                        >
                          {t("Rs.")}
                          <CountUp end={item.dec_productmrp} duration={2} />
                        </Typography>
                        <Typography
                          fontSize={18}
                          color="black"
                          style={{ fontWeight: "400" }}
                        >
                          {item.vac_productsubtitle}
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <Link to="/Productinfo"> */}
                        <Button
                          size="small"
                          onClick={() =>
                            this.navigateToMoreInfo(
                              item.vac_producttitle,
                              item.dec_productmrp,
                              item.vac_productsubtitle,
                              item.vac_productcategory,
                              item.vac_productimage,
                              item.vac_productdetails,
                              item.vac_netweight,
                              item.vac_productdosedetails
                            )
                          }
                        >
                          {t("View More")}
                        </Button>
                        {/* </Link> */}
                      </CardActions>
                    </Card>
                  ))
                : this.state.product
                    .filter(
                      (item) =>
                        item.vac_productcategory ===
                        (selectedCategory == "ખાતર"
                          ? "Fertilizer"
                          : selectedCategory == "જંતુનાશકો"
                          ? "Pesticides"
                          : selectedCategory == "બીજ"
                          ? "Seeds"
                          : selectedCategory)
                    )
                    .map((item, index) => (
                      <Card
                        // data-aos="zoom-out-up"
                        // data-aos="zoom-out"
                        data-aos="zoom-in"
                        sx={{
                          width: 300,
                          height: "auto",
                          background: "#96d1818c",
                          borderRadius: "12px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "280px",
                            height: "300px",
                            backgroundColor: "white",
                            display: "block",
                            margin: "8px auto",
                            borderRadius: "10px",
                          }}
                        >
                          <CardMedia
                            // data-aos="zoom-in"
                            sx={{
                              height: 300,
                              width: 200,
                              display: "block",
                              margin: "4px auto",
                              borderRadius: "20px",
                            }}
                            // image={apiURL + item.vac_productimage}/
                            image={
                              item.vac_productimage.includes(apiURL)
                                ? item.vac_productimage
                                : apiURL + item.vac_productimage
                            }
                            title="green iguana"
                          />
                        </div>
                        <CardContent
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography gutterBottom variant="h4" component="div">
                            {item.vac_producttitle}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h4"
                            style={{ fontWeight: "bold" }}
                            component="div"
                          >
                            {t(" Rs.")}
                            <CountUp end={item.dec_productmrp} duration={2} />
                          </Typography>
                          <Typography fontSize={18} color="black">
                            {item.vac_productsubtitle}
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            size="small"
                            onClick={() =>
                              this.navigateToMoreInfo(
                                item.vac_producttitle,
                                item.dec_productmrp,
                                item.vac_productsubtitle,
                                item.vac_productcategory,
                                item.vac_productimage,
                                item.vac_productdetails,
                                item.vac_netweight,
                                item.vac_productdosedetails
                              )
                            }
                          >
                            {t(" View More")}
                          </Button>
                        </CardActions>
                      </Card>
                    ))}
            </Box>
          </Container>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Product));
