// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css"; link of circle
import React, { useEffect } from "react";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import person from "../images/person.jpg";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { withTranslation } from "react-i18next";

const Whyus = ({ t }) => {
  useEffect(() => {
    Aos.init({
      duration: 2000, // Set the animation duration
    });
  }, []);

  const PercentageCircle = ({ percentage }) => {
    return (
      <div style={{ width: "80px" }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            pathColor: `rgba(133, 223, 43, 0.8)`,
            textColor: "black",
            trailColor: "rgba(133, 223, 43, 0.2)",
          })}
        />
      </div>
    );
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        marginTop={6}
        justifyContent="space-evenly"
        marginBottom={4}
      >
        <Grid item xs={10} md={5}>
          <CardMedia
            data-aos="zoom-in"
            sx={{ height: "400px" }}
            src={person}
            component="img"
            alt="banner"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Typography
            variant="h4"
            style={{ fontSize: "20px", color: "#70bd21", fontWeight: "bolder" }}
          >
            {t("WHY CHOOSE US?")}
          </Typography>
          {/* <Typography variant="h5" style={{ fontSize: "30px", color: "black" }}>
            <b>{t("Growing Healthy Food")}</b>
          </Typography> */}

          <Typography
            variant="body1"
            style={{
              fontSize: "18px",
              color: "#a3acab",
              textAlign: "justify",
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              hyphens: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: t(
                "Sustainable Farming Solutions: Our organic products are designed to enrich soil health, protect crops naturally, and enhance productivity.Farmer-Centric Approach: We work closely with the farming community, understanding their needs and delivering tailored solutions.Quality Assurance: Every product undergoes rigorous testing to meet the highest standards of purity and performance.Join us in our journey toward a greener, healthier planet. With MRProducts, grow better, grow greener."
              ),
            }}
          />

          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud ullamco laboris. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Cum eum voluptate laborum quidem
            distinctio, eligendi quam nostrum, est, excepturi ex beatae adipisci
            dicta delectus quo ea aliquid? Est nostrum, dolores ex error at
            voluptatum? */}

          {/* <Grid item justifyContent="center" alignItems="center">
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PercentageCircle percentage={75} />
              <Typography
                variant="h5"
                style={{
                  fontSize: "26px",
                  color: "black",
                  marginLeft: "10px",
                }}
              >
                <b>{t("Organic Solutions")}</b>
                <Typography
                  variant="body1"
                  style={{ fontSize: "18px", color: "#a3acab" }}
                >
                  {t("Fugiats pain is great")}
                </Typography>
              </Typography>
            </div>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(Whyus);
