import React, { Component } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import "./ProductList.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import p1 from "../ProductImages/firstProduct.png";
import p2 from "../ProductImages/secProduct.png";
import p3 from "../ProductImages/thirdProduct.png";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import img2 from "../images/a3.jpg";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { apiURL, fetchproductdetails } from "../API";
import { withRouter } from "../../HOC/withRouter";
import { withTranslation } from "react-i18next";

export class ProductList extends Component {
  state = {
    product: [],
  };
  componentDidMount() {
    Aos.init({
      duration: 1000,
    });
    this.onFormLoad();
  }

  componentWillReceiveProps(nextProps) {
    // This method is called when props are updated.
    this.onFormLoad(false);
  }

  onFormLoad = (scroll = true) => {
    let dataLoadStatus = false;
    this.setState({ dataLoadStatus }, () => {
      let reqData = {
        Op: "ProductList",
      };
      fetchproductdetails(reqData)
        .then((res) => {
          let product = [];

          if (
            localStorage.getItem("i18nextLng") == "eng" ||
            localStorage.getItem("i18nextLng") == "en"
          ) {
            res.data.ProductList.filter((row) => {
              if (row.vac_language == "Eng") {
                product.push(row);
              }
            });
          } else if (localStorage.getItem("i18nextLng") == "ગુજ") {
            res.data.ProductList.filter((row) => {
              if (row.vac_language == "Guj") {
                product.push(row);
              }
            });
          }

          this.setState({
            dataLoadStatus: true,
            product,
          });
          if (scroll) {
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          console.log("error", "Unkonwn Error occured in onFormLoad. " + error);
        });
    });
  };

  navigateToMoreInfo = (
    vac_producttitle,
    dec_productmrp,
    vac_productsubtitle,
    vac_productcategory,
    vac_productimage,
    vac_productdetails,
    vac_netweight,
    vac_productdosedetails
  ) => {
    // console.log("vac_productcategory", vac_productcategory);
    console.log(
      "title, price, description",
      vac_producttitle,
      dec_productmrp,
      vac_productsubtitle,
      vac_productcategory,
      vac_productimage,
      vac_productdetails,
      vac_netweight,
      vac_productdosedetails
    );
    this.props.navigateTo("Productinfo", {
      vac_producttitle: vac_producttitle,
      dec_productmrp: dec_productmrp,
      vac_productsubtitle: vac_productsubtitle,
      vac_productcategory: vac_productcategory,
      vac_productimage: vac_productimage,
      vac_productdetails: vac_productdetails,
      vac_netweight: vac_netweight,
      vac_productdosedetails: vac_productdosedetails,
      homepage: "homepage",
    });
  };

  render() {
    const { t } = this.props; // Access the translation function from props

    return (
      <div className="bgcolor" style={{ paddingBottom: "30px" }}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item paddingLeft={5} paddingRight={5}>
            <Typography
              variant="h1"
              style={{ fontSize: "30px", color: "black", fontWeight: "bold" }}
              marginTop={3}
            >
              <h3>{t("Product List")}</h3>
            </Typography>
          </Grid>
          <Grid item paddingLeft={5} paddingRight={5}>
            <Typography
              variant="h1"
              style={{
                fontSize: "30px",
                color: "black",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
              }}
              marginTop={3}
            >
              <h3>{t("View All Products")}</h3>
              <Link to="/Product">
                <ArrowCircleRightIcon
                  style={{
                    fontSize: "40px",
                    color: "#75c51b",
                    marginLeft: "10px",
                  }}
                />
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          item
          container
          direction="row"
          marginTop={2}
          justifyContent="space-evenly"
          alignItems="center"
          paddingBottom={4}
          spacing={4}
        >
          <Grid item alignItems="center" display="flex" flexDirection="column">
            <CardMedia
              sx={{ height: "300px" }}
              src={p1}
              component="img"
              alt="banner"
            />
            <Typography
              variant="h4"
              style={{ fontSize: "25px", color: "#077f00" }}
              marginTop={2}
            >
              <p>
                ઓલ ક્રોપ પ્લસ
                <br /> (ચોથ પ્રોમોટર) :
              </p>
            </Typography>
            <button>View More</button>
          </Grid>
          <Grid item alignItems="center" display="flex" flexDirection="column">
            <CardMedia
              sx={{ height: "300px" }}
              src={p2}
              component="img"
              alt="banner"
            />
            <Typography
              variant="h4"
              style={{ fontSize: "25px", color: "#077f00" }}
              marginTop={2}
            >
              <p>
                &nbsp; &nbsp; ફલાવર્સ
                <br /> (ફાલવરીંગ માટે):
              </p>
            </Typography>
            <button>View More</button>
          </Grid>
          <Grid item alignItems="center" display="flex" flexDirection="column">
            <CardMedia
              sx={{ height: "300px" }}
              src={p3}
              component="img"
              alt="banner"
            />
            <Typography
              variant="h4"
              style={{ fontSize: "25px", color: "#077f00" }}
              marginTop={2}
            >
              <p>
                &nbsp; &nbsp; પ્રોટેકટર
                <br /> (નીમ ઓઈલ) :
              </p>
            </Typography>
            <button>View More</button>
          </Grid>
        </Grid> */}

        <Grid container marginTop={3}>
          <Container>
            <Box
              display="flex"
              justifyContent="space-evenly"
              flexWrap="wrap"
              gap={4}
              alignItems="center"
              className="viewmore"
            >
              {this.state.product.slice(0, 3).map((item, index) => (
                <Card
                  // data-aos="zoom-out-up"
                  data-aos="zoom-out"
                  // data-aos="zoom-in"
                  sx={{
                    width: 300,
                    height: "auto",
                    background: "#96d1818c",
                    borderRadius: "12px",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: "280px",
                      height: "300px",
                      backgroundColor: "white",
                      display: "block",
                      margin: "8px auto",
                      borderRadius: "10px",
                    }}
                  >
                    <CardMedia
                      // data-aos="zoom-in"
                      sx={{
                        height: 300,
                        width: 200,
                        display: "block",
                        margin: "4px auto",
                        borderRadius: "20px",
                      }}
                      // image={img2}
                      image={
                        item.vac_productimage.includes(apiURL)
                          ? item.vac_productimage
                          : apiURL + item.vac_productimage
                      }
                    />
                  </div>
                  <CardContent
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ fontWeight: "bold" }}
                    >
                      {item.vac_producttitle}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{ fontWeight: "bold" }}
                    >
                      {t("Rs.")}
                      <CountUp end={item.dec_productmrp} duration={2} />
                    </Typography>
                    <Typography
                      fontSize={18}
                      color="black"
                      style={{ fontWeight: "400" }}
                    >
                      {item.vac_productsubtitle}
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Link to="/Productinfo"> */}
                    <Button
                      size="small"
                      onClick={() =>
                        this.navigateToMoreInfo(
                          item.vac_producttitle,
                          item.dec_productmrp,
                          item.vac_productcategory,
                          item.vac_productsubtitle,
                          item.vac_productimage,
                          item.vac_productdetails,
                          item.vac_netweight,
                          item.vac_productdosedetails
                        )
                      }
                    >
                      {t("View More")}
                    </Button>
                    {/* </Link> */}
                  </CardActions>
                </Card>
              ))}
            </Box>
          </Container>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ProductList));
