// import React, { useState } from "react";
// import "./Navbar.css";
// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaYoutubeSquare,
// } from "react-icons/fa";
// import MenuIcon from "@mui/icons-material/Menu";
// import Logo from "./images/Logo.jpeg";
// import { Link } from "react-router-dom";

// const Navbar = () => {
//   const [showMediaIcons, setShowMediaIcons] = useState(false);
//   const [navbar, setnavbar] = useState(false);
//   const navStyle = {
//     backgroundImage: 'url("./images/bg1.jpg")',
//   };

//   const changeBackground = () => {
//     if (window.scrollY >= 100) {
//       setnavbar(true);
//     } else {
//       setnavbar(false);
//     }
//   };

//   window.addEventListener("scroll", changeBackground);
//   return (
//     <>
//       <nav className={navbar ? "main-nav active" : "main-nav"} style={navStyle}>
//         {/* 1st logo part  */}
//         <div className="logo">
//           {/* <h2>
//             <span>MR </span>Products
//           </h2> */}
//           <img
//             src={Logo}
//             alt="MR Products Logo"
//             style={{ width: "220px", borderRadius: "8px" }}
//           />
//         </div>

//         {/* 2nd menu part  */}
//         <div
//           className={
//             showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
//           }
//         >
//           <ul>
//             <li>
//               <Link to="/">Home</Link>
//             </li>
//             <li>
//               <Link to="/Product">Products</Link>
//             </li>
//             <li>
//               <a href="#">About</a>
//             </li>
//             <li>
//               <a href="#">Distributors</a>
//             </li>
//             <li>
//               <a href="#">Contact</a>
//             </li>
//           </ul>
//         </div>

//         {/* 3rd social media links */}
//         <div className="social-media">
//           <ul className="social-media-desktop">
//             <li>
//               <a href="#" target="#">
//                 <FaFacebookSquare className="facebook" />
//               </a>
//             </li>
//             <li>
//               <a href="#" target="#">
//                 <FaInstagramSquare className="instagram" />
//               </a>
//             </li>
//             <li>
//               <a href="#" target="#">
//                 <FaYoutubeSquare className="youtube" />
//               </a>
//             </li>
//           </ul>

//           {/* hamburget menu start  */}
//           <div className="hamburger-menu">
//             <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
//               <MenuIcon />
//             </a>
//           </div>
//         </div>
//       </nav>
//       {/* <section className="hero-section">
//         <p>Welcome to </p>
//         <h1>MR PRODUCTS</h1>
//       </section> */}
//     </>
//   );
// };

// export default Navbar;

// import React, { Component } from "react";
// import "./Navbar.css";
// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaYoutubeSquare,
// } from "react-icons/fa";
// import MenuIcon from "@mui/icons-material/Menu";
// import Logo from "./images/Logo.jpeg";
// import { Link } from "react-router-dom";
// import { useNavigate, useLocation } from "react-router-dom";
// class Navbar extends Component {
//   state = {
//     showMediaIcons: false,
//     navbar: "false",
//   };

//   componentDidMount() {
//     window.addEventListener("scroll", this.changeBackground);
//     this.setState({ navbar: false });
//   }

//   changeBackground = () => {
//     if (window.scrollY >= 100) {
//       this.setState({ navbar: true });
//     } else {
//       this.setState({ navbar: false });
//     }
//   };

//   scrollUp = () => {
//     window.scrollTo(0, 0);
//   };

//   render() {
//     const { pathname } = this.props.location;
//     // const { pathname } = this.props.location;
//     // console.log("pathname", pathname);
//     return (
//       <>
//         <nav
//           className={this.state.navbar ? "main-nav active" : "main-nav"}
//           style={this.navStyle}
//         >
//           <div className="logo">
//             <img
//               src={Logo}
//               alt="MR Products Logo"
//               style={{ width: "220px", borderRadius: "8px" }}
//             />
//           </div>
//           <div
//             className={
//               this.state.showMediaIcons
//                 ? "menu-link mobile-menu-link"
//                 : "menu-link"
//             }
//           >
//             <ul>
//               <li>
//                 <Link to="/" onClick={this.scrollUp} className="fontsizelink">
//                   Home
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/Product"
//                   onClick={this.scrollUp}
//                   className="fontsizelink"
//                   // className={
//                   //   this.props.location.pathname === "/Product"
//                   //     ? "active-link"
//                   //     : "fontsizelink"
//                   // }
//                 >
//                   Products
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/AboutUs"
//                   onClick={this.scrollUp}
//                   className="fontsizelink"
//                 >
//                   About
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/Distributor"
//                   onClick={this.scrollUp}
//                   className="fontsizelink"
//                 >
//                   Distributor
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/ContactUs"
//                   onClick={this.scrollUp}
//                   className="fontsizelink"
//                 >
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </div>
//           <div className="social-media">
//             <ul className="social-media-desktop">
//               <li>
//                 <a href="#" target="#">
//                   <FaFacebookSquare className="facebook" />
//                 </a>
//               </li>
//               <li>
//                 <a href="#" target="#">
//                   <FaInstagramSquare className="instagram" />
//                 </a>
//               </li>
//               <li>
//                 <a href="#" target="#">
//                   <FaYoutubeSquare className="youtube" />
//                 </a>
//               </li>
//             </ul>
//             <div className="hamburger-menu">
//               <a
//                 onClick={() =>
//                   this.setState({ showMediaIcons: !this.state.showMediaIcons })
//                 }
//               >
//                 <MenuIcon />
//               </a>
//             </div>
//           </div>
//         </nav>
//       </>
//     );
//   }
// }

// export default Navbar;

import React, { useState, useEffect } from "react";
import "./Navbar.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./images/Logo.jpeg";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { withTranslation } from "react-i18next";

const Navbar = ({ t }) => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const location = useLocation();
  const isItemActive = (path) => location.pathname === path;

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 100) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener("scroll", changeBackground);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    // <>
    //   <nav
    //     spacing={{ margin: "8px" }}
    //     className={navbar ? "main-nav active" : "main-nav"}
    //   >
    //     <div className="logo">
    //       <Link to="/">
    //         <img
    //           src={Logo}
    //           alt="MR Products Logo"
    //           style={{ width: "180px", borderRadius: "8px" }}
    //         />
    //       </Link>
    //     </div>
    //     <div
    //       className={
    //         showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
    //       }
    //     >
    //       <ul>
    //         <li>
    //           <Link
    //             to="/"
    //             onClick={scrollUp}
    //             // className="fontsizelink"
    //             className={isItemActive("/") ? "active-link" : "fontsizelink"}
    //           >
    //             {t("Home")}
    //           </Link>
    //         </li>
    //         <li>
    //           <Link
    //             to="/Product"
    //             onClick={scrollUp}
    //             className={
    //               isItemActive("/Product") ? "active-link" : "fontsizelink"
    //             }
    //           >
    //             {t("Products")}
    //           </Link>
    //         </li>
    //         <li>
    //           <Link
    //             to="/AboutUs"
    //             onClick={scrollUp}
    //             className={
    //               isItemActive("/AboutUs") ? "active-link" : "fontsizelink"
    //             }
    //           >
    //             {t("About")}
    //           </Link>
    //         </li>
    //         <li>
    //           <Link
    //             to="/Distributor"
    //             onClick={scrollUp}
    //             className={
    //               isItemActive("/Distributor") ? "active-link" : "fontsizelink"
    //             }
    //           >
    //             {t("Distributor")}
    //           </Link>
    //         </li>
    //         <li>
    //           <Link
    //             to="/ContactUs"
    //             onClick={scrollUp}
    //             className={
    //               isItemActive("/ContactUs") ? "active-link" : "fontsizelink"
    //             }
    //           >
    //             {t("Contact")}
    //           </Link>
    //         </li>
    //         {/* {location.pathname != "/Productinfo" ? ( */}
    //         <li className={"languageListItem"}>
    //           <LanguageSwitcher
    //             disabled={location.pathname === "/Productinfo"}
    //           />
    //         </li>
    //         {/* ) : (
    //           ""
    //         )} */}
    //       </ul>
    //     </div>
    //     <div className="social-media">
    //       <ul className="social-media-desktop">
    //         <li>
    //           <a href="#" target="#">
    //             <FaFacebookSquare className="facebook" />
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="https://www.instagram.com/mrproducts32?igsh=amptajF4Zmw3Zmw3"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             aria-label="Visit our Instagram page"
    //           >
    //             <FaInstagramSquare className="instagram" />
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="https://youtube.com/@mr_products123?si=i5CxNX4S_qwMeuCH"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             aria-label="Visit our Instagram page"
    //           >
    //             <FaYoutubeSquare className="youtube" />
    //           </a>
    //         </li>
    //       </ul>
    //       <div className="hamburger-menu">
    //         <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
    //           <MenuIcon />
    //         </a>
    //       </div>
    //     </div>
    //   </nav>
    // </>

    <>
      <nav className={navbar ? "main-nav active" : "main-nav"}>
        <div className="logo">
          <Link to="/">
            <img
              src={Logo}
              alt="MR Products Logo"
              style={{ width: "180px", borderRadius: "8px" }}
            />
          </Link>
        </div>
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li>
              <Link
                to="/"
                onClick={scrollUp}
                className={isItemActive("/") ? "active-link" : "fontsizelink"}
              >
                {t("Home")}
              </Link>
            </li>
            <li>
              <Link
                to="/Product"
                onClick={scrollUp}
                className={
                  isItemActive("/Product") ? "active-link" : "fontsizelink"
                }
              >
                {t("Products")}
              </Link>
            </li>
            <li>
              <Link
                to="/AboutUs"
                onClick={scrollUp}
                className={
                  isItemActive("/AboutUs") ? "active-link" : "fontsizelink"
                }
              >
                {t("About")}
              </Link>
            </li>
            <li>
              <Link
                to="/Distributor"
                onClick={scrollUp}
                className={
                  isItemActive("/Distributor") ? "active-link" : "fontsizelink"
                }
              >
                {t("Distributor")}
              </Link>
            </li>
            <li>
              <Link
                to="/ContactUs"
                onClick={scrollUp}
                className={
                  isItemActive("/ContactUs") ? "active-link" : "fontsizelink"
                }
              >
                {t("Contact")}
              </Link>
            </li>
            <li className="languageListItem">
              <LanguageSwitcher
                disabled={location.pathname === "/Productinfo"}
              />
            </li>
          </ul>
        </div>
        <div className="social-media">
          <ul className="social-media-desktop">
            <li>
              <a href="#" target="_self">
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mrproducts32"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/@mr_products123"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
          </ul>
          <div className="hamburger-menu">
            <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <MenuIcon />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withTranslation()(Navbar);
