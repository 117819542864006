// import React from "react";
// import Banner from "./Banner";
// import Ourinfo from "./Ourinfo";
// import "./Banner.css";
// import ProductList from "./ProductList";
// import Whyus from "./Whyus";
// import Testimonials from "./Testimonials";
// import Branch from "./Branch";

// function Home() {
//   return (
//     <div>
//       <Banner />
//       <Ourinfo />
//       <ProductList />
//       <Whyus />
//       <Testimonials />
//       <Branch />
//     </div>
//   );
// }

// export default Home;

import React, { Component } from "react";
import Banner from "./Banner";
import Ourinfo from "./Ourinfo";
import "./Banner.css";
import ProductList from "./ProductList";
import Whyus from "./Whyus";
import Testimonials from "./Testimonials";
import Branch from "./Branch";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Banner />
        <Ourinfo />
        <ProductList />
        <Whyus />
        <Testimonials />
        <Branch />
      </div>
    );
  }
}

export default Home;
