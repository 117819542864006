import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";
import React, { Component } from "react";
import Image2 from "./images/bg1.jpg";
import { Button, CardMedia, Stack, Typography } from "@mui/material";
import CtTxt from "./CustomTool/CtTxt";
import CtTxtAmt from "./CustomTool/CtTxtAmt";
import CtBtn from "./CustomTool/CtBtn";
import SendIcon from "@mui/icons-material/Send";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import img2 from "./images/a3.jpg";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { fetchdistributordetails, fetchproductdetails } from "./API";
import {
  StoreMallDirectory,
  StoreMallDirectoryOutlined,
} from "@mui/icons-material";
import boron from "./images/boron.png";
import Cu from "./images/Cu.png";
import FE from "./images/FE.png";
import MG from "./images/MG.png";
import Nitrogen from "./images/Nitrogen.png";
import Phosphorus from "./images/Phosphorus.png";
import calcium from "./images/calcium.png";
import zinc from "./images/zinc.png";
import sulfur from "./images/sulfur.png";
import magnes from "./images/magnes.png";
import molidenum from "./images/molidenum.png";
import potash from "./images/potash.png";
import imgBanner2 from "./images/bg2.jpg";
import nature1 from "./images/nature1.jpg";
import nature2 from "./images/nature2.jpg";
import nature3 from "./images/nature3.jpg";
import AboutUsMRProduct from "./images/AboutUSMR.jpg";
import { withTranslation } from "react-i18next";

class AboutUs extends Component {
  state = {
    cardcontent: [
      {
        img: boron,
        title: "Boron",
        first: "BoronDescription1",
        second: "BoronDescription2",
      },
      {
        img: calcium,
        title: "Calcium",
        first: "CalciumDescription1",
        second: "CalciumDescription2",
      },
      {
        img: FE,
        title: "Iron",
        first: "IronDescription1",
        second: "IronDescription2",
      },
      {
        img: sulfur,
        title: "Sulfur",
        first: "SulfurDescription1",
        second: "SulfurDescription2",
      },
      {
        img: magnes,
        title: "Manganese",
        first: "ManganeseDescription1",
        second: "ManganeseDescription2",
      },
      {
        img: Cu,
        title: "Copper",
        first: "CopperDescription1",
        second: "CopperDescription2",
      },
      {
        img: zinc,
        title: "Zinc",
        first: "ZincDescription1",
        second: "ZincDescription2",
      },
      {
        img: molidenum,
        title: "Molybdenum",
        first: "MolybdenumDescription1",
        second: "MolybdenumDescription2",
      },
      {
        img: MG,
        title: "Magnesium",
        first: "MagnesiumDescription1",
        second: "MagnesiumDescription2",
      },
      {
        img: potash,
        title: "Potash",
        first: "PotashDescription1",
        second: "PotashDescription2",
      },
      {
        img: Nitrogen,
        title: "Nitrogen",
        first: "NitrogenDescription1",
        second: "NitrogenDescription2",
      },
      {
        img: Phosphorus,
        title: "Phosphorus",
        first: "PhosphorusDescription1",
        second: "PhosphorusDescription2",
      },
    ],
    imagecard: [
      {
        img: nature1,
        title: "NeutrallyProduced",
        first: "NeutrallyProducedDescription",
      },
      {
        img: nature2,
        title: "ProtectNature",
        first: "ProtectNatureDescription",
      },
      {
        img: nature3,
        title: "Nutrients",
        first: "NutrientsDescription",
      },
    ],
  };
  componentDidMount() {
    Aos.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props; // Access the translation function from props

    return (
      <div>
        <CardMedia
          sx={{ height: "300px", marginTop: "-115px" }}
          src={Image2}
          component="img"
          alt="banner"
        />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{
            textAlign: "center",
            marginBottom: "50px",
            marginTop: "50px",
          }}
        >
          <Grid item xs={10} sm={10} lg={5} xl={5}>
            <CardMedia
              data-aos="fade-down"
              data-aos-duration="500"
              sx={{ height: "auto", marginBottom: "20px" }}
              src={AboutUsMRProduct}
              component="img"
              alt="banner"
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={5} xl={5}>
            <Stack direction="row">
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "50px",
                  fontWeight: "700",
                  color: "#75c51b",
                }}
              >
                {t("Why the need for Organic manure ?")}
                <br />
                <div
                  style={{
                    width: "30%",
                    height: "1px",
                    background: "#006838",
                    margin: "30px 0px",
                  }}
                ></div>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#5c5c5c",
                  }}
                >
                  {t("why us first paragraph")}
                </p>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            textAlign: "center",
            backgroundColor: "#96d181",
            // paddingBottom: "40px",
          }}
        >
          {/* <Grid
            item
            lg={8}
            xl={8}
            sm={10}
            xs={10}
            style={{ margin: "50px 0px" }}
          > */}
          <Typography
            style={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              color: "white",
              fontFamily: "inherit",
              marginTop: "50px",
              // padding: "0px 10px",
            }}
          >
            {t("Importance of micronutrients")}
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "#006838",
                margin: "10px 0px",
              }}
            ></div>
            <p style={{ fontSize: "22px", fontWeight: "400", color: "white" }}>
              {t(
                "Organic carbon, micro newtons, is currently deficient in all soils. MR Products Fertilizer contains micro newtons, and organic carbon, which is essential as a MR Products fertilizer."
              )}
            </p>
          </Typography>
          {/* </Grid> */}
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ padding: "0px 40px" }}
            >
              {this.state.cardcontent.map((card, index) => (
                <Grid
                  item
                  lg={4}
                  xl={3}
                  sm={6}
                  xs={12}
                  style={{ padding: "20px" }}
                  key={index}
                >
                  <Card
                    data-aos="fade-up"
                    sx={{
                      width: 300,
                      height: "auto",
                      background: "black",
                      padding: "20px",
                    }}
                    style={{ borderRadius: "20px" }}
                  >
                    <CardContent>
                      <Stack direction="row">
                        <img
                          src={card.img}
                          alt={card.title}
                          style={{
                            maxWidth: "35%",
                            height: "40%",
                            borderRadius: "12px",
                            paddingRight: "10px",
                          }}
                        />
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ textAlign: "left" }}
                        >
                          <h2>{t(card.title)}</h2>
                          <p style={{ fontSize: "16px" }}>
                            <span style={{ fontSize: "12px" }}>&#x2B24;</span>{" "}
                            {t(card.first)} <br />
                            <span style={{ fontSize: "12px" }}>
                              &#x2B24;
                            </span>{" "}
                            {t(card.second)}
                          </p>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ marginTop: "20px" }}
        >
          <Grid item xl={10} lg={10} sm={10} xs={10}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "50px",
                fontWeight: "bold",
                color: "black",
                fontFamily: "inherit",
              }}
            >
              {t("What is Organic Products?")}
              <br />
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "400",
                  color: "#5c5c5c",
                }}
              >
                {t("ProtectNatureDescription")}
              </p>
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              style={{ padding: "0px 40px" }}
            >
              {this.state.imagecard.map((card, index) => (
                <Grid
                  item
                  lg={4}
                  xl={3}
                  sm={6}
                  xs={12}
                  style={{ padding: "20px" }}
                  key={index}
                >
                  <div
                    sx={{
                      width: 300,
                      height: "auto",
                      background: "black",
                      padding: "20px",
                    }}
                    style={{ borderRadius: "20px" }}
                  >
                    <CardContent>
                      <Stack direction="column">
                        <img
                          data-aos="zoom-in"
                          src={card.img}
                          alt={card.title}
                          style={{
                            maxWidth: "100%",
                            height: "100%",
                            borderRadius: "12px",
                          }}
                        />
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ textAlign: "justify" }}
                        >
                          <h2 style={{ textAlign: "center" }}>
                            {t(card.title)}
                          </h2>
                          <p style={{ fontSize: "24px" }}>{t(card.first)}</p>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default HoCtToastContainer(withTranslation()(AboutUs));
