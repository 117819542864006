// import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
// import React from "react";
// import StoreIcon from "@mui/icons-material/Store";
// import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
// import { Link } from "react-router-dom";

// function Branch() {
//   const span = {
//     color: "#006838",
//   };
//   return (
//     <div>
//       <Grid container alignItems="center" justifyContent="space-between ">
//         <Grid item marginLeft={4}>
//           <Typography
//             variant="h1"
//             style={{ fontSize: "30px", color: "black", fontWeight: "bold" }}
//             marginTop={3}
//           >
//             <h3>Our Branches</h3>
//           </Typography>
//         </Grid>
//         <Grid item marginRight={4}>
//           <Link to="/Distributor">
//             <Typography
//               variant="h1"
//               style={{
//                 fontSize: "30px",
//                 color: "black",
//                 fontWeight: "bold",
//                 display: "flex",
//                 flexDirection: "row",
//               }}
//               marginTop={3}
//             >
//               <h3>View All Branches</h3>
//               <ArrowCircleRightIcon
//                 style={{
//                   fontSize: "40px",
//                   color: "#75c51b",
//                   marginLeft: "10px",
//                 }}
//               />
//             </Typography>
//           </Link>
//         </Grid>
//       </Grid>
//       <Grid
//         container
//         direction="row"
//         justifyContent="space-evenly"
//         alignItems="center"
//         marginTop={1}
//       >
//         <Grid
//           item
//           xl={4}
//           lg={5}
//           md={5.5}
//           xs={8}
//           marginTop={3}
//           padding={2}
//           style={{
//             background: "rgb(20,21,20,0.1)",
//             borderRadius: "10px",
//             display: "flex",
//             alignItems: "center",
//             flexDirection: "column",
//             justifyContent: "space-between",
//           }}
//         >
//           <Grid
//             style={{
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "row",
//               justifyContent: "space-around",
//             }}
//           >
//             <Typography
//               style={{
//                 color: "black",
//                 fontWeight: "bolder",
//               }}
//             >
//               <h2 style={{ fontSize: "20px" }}>
//                 <span style={span}>Branch Name</span> : Branch 1
//               </h2>
//               <h3 style={{ fontSize: "18px" }}>
//                 {" "}
//                 <span style={span}>Mobile No</span> : 1234567890
//               </h3>
//               <h4 style={{ fontSize: "18px" }}>
//                 <span style={span}>Distributor Name </span> : Faizan
//               </h4>
//               {/* <StoreIcon style={{ fontSize: "200px", color: "#75c51b" }} /> */}
//             </Typography>
//             <StoreIcon style={{ fontSize: "150px", color: "#75c51b" }} />
//           </Grid>
//           <Grid
//             style={{
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "row",
//               justifyContent: "space-around",
//             }}
//           >
//             <p
//               style={{
//                 color: "black",
//                 fontWeight: "bolder",
//                 padding: "24px",
//                 textAlign: "justify",
//               }}
//             >
//               <span style={span}>Address</span> : Lorem ipsum dolor sit amet
//               elit. Velit beatae laudantium voluptate rem ullam dolore nisi
//               voluptatibus esse quasi, doloribus tempora. Dolores molestias
//               adipisci dolo amet!. Lorem ipsum dolor sit amet consectetur
//               adipisicing elit. Esse architecto est ea sunt eligendi cum?
//             </p>
//           </Grid>
//         </Grid>
//         <Grid
//           item
//           xl={4}
//           lg={5}
//           md={5.5}
//           xs={8}
//           marginTop={3}
//           padding={2}
//           style={{
//             background: "rgb(20,21,20,0.1)",
//             borderRadius: "10px",
//             display: "flex",
//             alignItems: "center",
//             flexDirection: "column",
//           }}
//         >
//           <Grid
//             style={{
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "row",
//               justifyContent: "flex-start",
//             }}
//           >
//             <Typography
//               style={{
//                 color: "black",
//                 fontWeight: "bolder",
//               }}
//             >
//               <h2 style={{ fontSize: "20px" }}>
//                 <span style={span}>Branch Name</span> : Branch 2
//               </h2>
//               <h3 style={{ fontSize: "18px" }}>
//                 {" "}
//                 <span style={span}>Mobile No</span> : 9876543210
//               </h3>
//               <h4 style={{ fontSize: "18px" }}>
//                 <span style={span}>Distributor Name </span> : Sheikh
//               </h4>
//               {/* <StoreIcon style={{ fontSize: "200px", color: "#75c51b" }} /> */}
//             </Typography>
//             <StoreIcon style={{ fontSize: "150px", color: "#75c51b" }} />
//           </Grid>
//           <Grid>
//             <p
//               style={{
//                 color: "black",
//                 fontWeight: "bolder",
//                 padding: "24px",
//                 textAlign: "justify",
//               }}
//             >
//               <span style={span}>Address</span> : Lorem ipsum dolor sit amet
//               elit. Velit beatae laudantium voluptate rem ullam dolore nisi
//               voluptatibus esse quasi, doloribus tempora. Dolores molestias
//               adipisci dolo amet!. Lorem ipsum dolor sit amet consectetur
//               adipisicing elit. Esse architecto est ea sunt eligendi cum?
//             </p>
//           </Grid>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Branch;

import React, { Component } from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import { fetchdistributordetails } from "../API";
import { withTranslation } from "react-i18next";

class Branch extends Component {
  state = {
    distributor: [],
  };

  componentDidMount() {
    // Aos.init({
    //   duration: 1000,
    // });
    this.onFormLoad();
  }

  onFormLoad = () => {
    let dataLoadStatus = false;
    this.setState({ dataLoadStatus }, () => {
      let reqData = {
        Op: "DistributorList",
      };
      fetchdistributordetails(reqData)
        .then((res) => {
          // let distributor = res.data.DistributorList;

          let distributor = [];

          // console.log('(localStorage.getItem("i18nextLng") == "en"',localStorage.getItem("i18nextLng") )
          if (localStorage.getItem("i18nextLng") == "eng") {
            res.data.DistributorList.filter((row) => {
              if (row.vac_language == "Eng") {
                distributor.push(row);
              }
            });
          } else if (localStorage.getItem("i18nextLng") == "ગુજ") {
            res.data.DistributorList.filter((row) => {
              if (row.vac_language == "Guj") {
                distributor.push(row);
              }
            });
          }

          this.setState({
            dataLoadStatus: true,
            distributor,
          });
          // window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log("error", "Unkonwn Error occured in onFormLoad. " + error);
        });
    });
  };
  render() {
    const { t } = this.props; // Access the translation function from props
    const span = {
      color: "#006838",
    };
    const h2 = {
      fontSize: "20px",
      padding: "4px 0px",
    };

    return (
      <div>
        <Grid container alignItems="center" justifyContent="space-between ">
          <Grid item marginLeft={4}>
            <Typography
              variant="h1"
              style={{ fontSize: "30px", color: "black", fontWeight: "bold" }}
              marginTop={3}
            >
              <h3>{t("Our Branches")}</h3>
            </Typography>
          </Grid>
          <Grid item marginRight={4}>
            <Link to="/Distributor">
              <Typography
                variant="h1"
                style={{
                  fontSize: "30px",
                  color: "black",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                }}
                marginTop={3}
              >
                <h3>{t("View All Branches")}</h3>
                <ArrowCircleRightIcon
                  style={{
                    fontSize: "40px",
                    color: "#75c51b",
                    marginLeft: "10px",
                  }}
                />
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          marginTop={1}
        >
          {this.state.distributor.slice(0, 2).map((item) => (
            <Grid
              item
              xl={4}
              lg={5}
              md={5.5}
              xs={8}
              marginTop={3}
              padding={2}
              style={{
                background: "rgb(20,21,20,0.1)",
                borderRadius: "10px",
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bolder",
                }}
              >
                <h2 style={h2}>
                  <span style={span}>{t("Branch Name")}</span> :{" "}
                  {item.vac_branchname}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Branch Number")}</span> :{" "}
                  {item.vac_branchnumber}
                </h2>
                <h2 style={h2}>
                  {" "}
                  <span style={span}>{t("Mobile No")}</span> :{" "}
                  {item.vac_branchmobileno}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Distributor Name")}</span> :{" "}
                  {item.vac_distributorname}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("State")}</span> :{" "}
                  {item.vac_branchstate}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("District")} </span> :{" "}
                  {item.vac_branchdistrict}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Taluka")} </span> :{" "}
                  {item.vac_branchtaluka}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Address")} </span> :{" "}
                  {item.vac_branchaddress}
                </h2>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(Branch);
