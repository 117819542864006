import React, { Component } from "react";
import Image2 from "./images/bg1.jpg";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Button, Grid } from "@mui/material";
import "./Product.css";
import img2 from "./images/a3.jpg";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { fetchdistributordetails } from "./API";
import { withRouter } from "../HOC/withRouter";
import StoreIcon from "@mui/icons-material/Store";
import { withTranslation } from "react-i18next";

class Product extends Component {
  state = {
    distributor: [],
  };

  componentDidMount() {
    Aos.init({
      duration: 1000,
    });
    this.onFormLoad();
  }

  componentWillReceiveProps(nextProps) {
    // This method is called when props are updated.
    // this.checkLanguageChange();
    this.onFormLoad();
  }

  onFormLoad = () => {
    let dataLoadStatus = false;
    this.setState({ dataLoadStatus }, () => {
      let reqData = {
        Op: "DistributorList",
      };
      fetchdistributordetails(reqData)
        .then((res) => {
          // let distributor = res.data.DistributorList;
          let distributor = [];

          // console.log('(localStorage.getItem("i18nextLng") == "en"',localStorage.getItem("i18nextLng") )
          if (localStorage.getItem("i18nextLng") == "eng") {
            res.data.DistributorList.filter((row) => {
              if (row.vac_language == "Eng") {
                distributor.push(row);
              }
            });
          } else if (localStorage.getItem("i18nextLng") == "ગુજ") {
            res.data.DistributorList.filter((row) => {
              if (row.vac_language == "Guj") {
                distributor.push(row);
              }
            });
          }

          this.setState({
            dataLoadStatus: true,
            distributor,
          });
          // window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log("error", "Unkonwn Error occured in onFormLoad. " + error);
        });
    });
  };

  render() {
    const { t } = this.props; // Access the translation function from props

    const span = {
      color: "#006838",
    };

    const h2 = {
      fontSize: "20px",
      padding: "4px 0px",
    };

    return (
      <div className="Product">
        <CardMedia
          sx={{ height: "300px", marginTop: "-115px" }}
          src={Image2}
          component="img"
          alt="banner"
        />
        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "38px",
          }}
        >
          {t("MR Product Branches")}
        </h1>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          marginTop={1}
        >
          {this.state.distributor.map((item) => (
            <Grid
              data-aos="fade-up"
              item
              xl={4}
              lg={5}
              md={5.5}
              xs={8}
              marginTop={3}
              padding={2}
              style={{
                background: "rgb(20,21,20,0.1)",
                borderRadius: "10px",
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: "5px",
              }}
            >
              {/* <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              > */}
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bolder",
                }}
              >
                <h2 style={h2}>
                  <span style={span}>{t("Branch Name")}</span> :{" "}
                  {item.vac_branchname}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Branch Number")}</span> :{" "}
                  {item.vac_branchnumber}
                </h2>
                <h2 style={h2}>
                  {" "}
                  <span style={span}>{t("Mobile No")}</span> :{" "}
                  {item.vac_branchmobileno}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Distributor Name")} </span> :{" "}
                  {item.vac_distributorname}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("State")} </span> :{" "}
                  {item.vac_branchstate}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("District")}</span> :{" "}
                  {item.vac_branchdistrict}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Taluka")} </span> :{" "}
                  {item.vac_branchtaluka}
                </h2>
                <h2 style={h2}>
                  <span style={span}>{t("Address")}</span> :{" "}
                  {item.vac_branchaddress}
                </h2>
              </Typography>
              {/* <StoreIcon style={{ fontSize: "180px", color: "#75c51b" }} /> */}
              {/* </Grid> */}
              {/* <Grid> */}
              {/* <Typography>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                  }}
                >
                  <span style={{ wordWrap: "break-word", color: "#006838" }}>
                    Branch State
                  </span>{" "}
                  : {item.vac_branchstate}
                </p>
              </Typography>
              <Typography>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                  }}
                >
                  <span style={{ wordWrap: "break-word", color: "#006838" }}>
                    Branch District
                  </span>{" "}
                  : {item.vac_branchdistrict}
                </p>
              </Typography>
              <Typography>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    textAlign: "left",
                  }}
                >
                  <span style={{ wordWrap: "break-word", color: "#006838" }}>
                    Address
                  </span>{" "}
                  : {item.vac_branchaddress}
                </p>
              </Typography> */}
            </Grid>
            // </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Product));
