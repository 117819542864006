import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import "./Testimonials.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rohitPhoto from "../images/01.jpg";
import truptibenPhoto from "../images/02.jpg";
import jevinPhoto from "../images/03.jpg";
import LanguageSwitcher from "../LanguageSwitcher"; // Import the LanguageSwitcher
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

// const CustomPrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         background: "black",
//         borderRadius: "50%",
//         position: "absolute",
//         left: "20px",
//         zIndex: 1,
//         top: "50%",
//         transform: "translateY(-50%)",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// const CustomNextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         background: "black",
//         borderRadius: "50%",
//         position: "absolute",
//         right: "20px",
//         zIndex: 1,
//         top: "50%",
//         transform: "translateY(-50%)",
//       }}
//       onClick={onClick}
//     />
//   );
// };

const Testimonials = ({ t }) => {
  const testimonials = [
    {
      photo: rohitPhoto,
      key: "rohit", // Use the key for translation
    },
    {
      photo: truptibenPhoto,
      key: "truptiben", // Use the key for translation
    },
    {
      photo: jevinPhoto,
      key: "jevin", // Use the key for translation
    },
  ];

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={className}
        style={{
          ...style,
          zIndex: 0, // Add z-index here
          position: "absolute", // Ensure z-index works
          left: "10px", // Adjust as needed
        }}
        onClick={onClick}
      >
        ❮ {/* Replace with an icon or text */}
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          zIndex: 0, // Add z-index here
          position: "absolute", // Ensure z-index works
          right: "10px", // Adjust as needed
        }}
        onClick={onClick}
      >
        ❯ {/* Replace with an icon or text */}
      </div>
    );
  };

  const sliderSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite scrolling
    speed: 500, // Speed of the slide transition
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable auto-play
    autoplaySpeed: 2000, // Time between auto-play transitions
    pauseOnHover: true, // Pause auto-play when hovered
    arrows: true, // Enable arrows (next/previous)

    customPaging: (i) => (
      <div className="custom-dot" style={{ position: "relative" }}>
        <span className="dot"></span>
      </div>
    ),
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="imgOfTestimonialsPage"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="text">
          <Typography
            component="h2"
            style={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              margin: "10px 0px",
              paddingTop: "20px",
            }}
          >
            <b>{t("HAPPY CLIENTS")}</b>
          </Typography>
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.3)",
              padding: "30px 10px",
            }}
          >
            <Slider {...sliderSettings}>
              {testimonials.map((testimonial, index) => (
                <>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"row"}
                  >
                    <Grid item lg={2} md={2}>
                      <div
                        style={{
                          width: "190px",
                          height: "190px",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          overflow: "hidden",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f8f8f8",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          marginRight: "20px",
                        }}
                      >
                        <img
                          src={testimonial.photo}
                          alt={`Testimonial ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={8} xs={8}>
                      <Typography
                        variant="h6"
                        style={{
                          marginTop: "2vh",
                          color: "#75faeb",
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        {t(`testimonials.${testimonial.key}.caption`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          marginTop: "1vh",
                          color: "white",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {t(`testimonials.${testimonial.key}.description`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Testimonials);
