import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import Image2 from "./images/bg1.jpg";
import { Button, CardMedia, Stack, Typography } from "@mui/material";
import CtTxt from "./CustomTool/CtTxt";
import CtTxtAmt from "./CustomTool/CtTxtAmt";
import CtBtn from "./CustomTool/CtBtn";
import SendIcon from "@mui/icons-material/Send";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { fetchcontactus } from "./API";
import { withTranslation } from "react-i18next";

class ContactUs extends Component {
  state = {
    formData: {
      txtname: "",
      txtemail: "",
      txtmobileno: "",
      txtsubject: "",
      txtmessage: "",
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  validateContactUs = () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidMobile = /^[0-9]{10}$/g;
    let result = false;
    if (this.state.formData.txtname.length <= 0) {
      this.props.toastErrorMsg("Enter your Name", "txtname");
    } else if (this.state.formData.txtemail.length <= 0) {
      this.props.toastErrorMsg("Enter Email", "txtemail");
    } else if (!isValidEmail.test(this.state.formData.txtemail)) {
      this.props.toastErrorMsg("Enter valid Email", "txtemail");
    } else if (!isValidMobile.test(this.state.formData.txtmobileno)) {
      this.props.toastErrorMsg("Enter valid Mobile No", "txtmobileno");
    } else if (this.state.formData.txtmobileno.length <= 0) {
      this.props.toastErrorMsg("Enter Mobile No", "txtmobileno");
    } else if (this.state.formData.txtsubject.length <= 0) {
      this.props.toastErrorMsg("Enter Subject", "txtsubject");
    } else if (this.state.formData.txtmessage.length <= 0) {
      this.props.toastErrorMsg("Enter Message", "txtmessage");
    } else {
      result = true;
    }
    return result;
  };

  ClearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        txtname: "",
        txtemail: "",
        txtmessage: "",
        txtmobileno: "",
        txtsubject: "",
      },
    });
  };

  SaveContactUs = () => {
    if (this.validateContactUs()) {
      // this.props.toastErrorMsg("send email backend Code Reamin");
      this.props.notifyProcessing();
      let dataLoadStatus = false;
      this.setState({ dataLoadStatus }, () => {
        let reqData = {
          Op: "SendMail",
          Name: this.state.formData.txtname,
          Email: this.state.formData.txtemail,
          MobileNo: this.state.formData.txtmobileno,
          Subject: this.state.formData.txtsubject,
          Message: this.state.formData.txtmessage,
        };
        fetchcontactus(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            this.setState(
              {
                dataLoadStatus: true,
              },
              () => {
                this.ClearInfo();
              }
            );
          })
          .catch((error) => {
            console.log(
              "error",
              "Unkonwn Error occured in SaveContactUs. " + error
            );
          });
      });
    }
  };

  render() {
    const { t } = this.props; // Access the translation function from props
    // console.log("this.state.hasReloaded", this.state.hasReloaded);

    return (
      <div className="Product">
        <CardMedia
          sx={{ height: "300px", marginTop: "-115px" }}
          src={Image2}
          component="img"
          alt="banner"
        />
        <Typography
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "38px",
            color: "black",
          }}
        >
          {t("Get in touch")}
          <span style={{ color: "#75c51b" }}> {t("with us")}</span>
        </Typography>
        <p
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "24px",
            color: "#6a6d69",
            wordWrap: "break-word",
          }}
        >
          {t(
            "You can contact us with anything related to our Products or Services.Well get in touch with you as soon as possible."
          )}
        </p>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          <Grid
            item
            xl={6}
            lg={3}
            sm={12}
            xs={12}
            style={{ marginTop: "30px" }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              // spacing={2}
            >
              <Grid item style={{ marginTop: "12px" }}>
                <CtTxt
                  id="txtname"
                  label="Name"
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtname}
                  width={300}
                  maxLength={60}
                  nextCtrlID="txtProducttitle"
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item style={{ marginTop: "12px" }}>
                <CtTxt
                  id="txtemail"
                  label="Email"
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtemail}
                  width={300}
                  maxLength={100}
                  nextCtrlID="txtProducttitle"
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item style={{ marginTop: "12px" }}>
                <CtTxtAmt
                  id="txtmobileno"
                  label="Mobile No."
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtmobileno}
                  width={300}
                  maxLength={10}
                  nextCtrlID="txtProducttitle"
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item style={{ marginTop: "12px" }}>
                <CtTxt
                  id="txtsubject"
                  label="Subject"
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtsubject}
                  width={300}
                  maxLength={500}
                  nextCtrlID="txtProducttitle"
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item style={{ marginTop: "12px" }}>
                <CtTxt
                  id="txtmessage"
                  label="Message"
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtmessage}
                  width={300}
                  maxLength={4000}
                  nextCtrlID="txtProducttitle"
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item style={{ marginTop: "18px" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#75c51b" }}
                  endIcon={<SendIcon />}
                  onClick={this.SaveContactUs}
                >
                  {t("Send")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={6}
            lg={3}
            sm={12}
            xs={12}
            style={{ marginTop: "30px" }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              // spacing={3}
            >
              <Grid item>
                <Stack direction="row" spacing={3}>
                  <RoomIcon style={{ color: "#75c51b", fontSize: "50px" }} />

                  <Typography
                    style={{
                      textAlign: "justify",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#75c51b",
                    }}
                  >
                    {t("ADDRESS")} <br />
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5c5c5c",
                      }}
                    >
                      {t("Plot No.")}366/5,
                      <br />
                      {t("Chitra GIDC,")}
                      <br />
                      {t("Bhavnagar(Guj. Ind.)")}
                      <br />
                      {t("Gujarat,India.")}
                      <br />
                      {t("Pincode")}:364-002
                    </p>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={3}>
                  <CallIcon style={{ color: "#75c51b", fontSize: "50px" }} />
                  <Typography
                    style={{
                      textAlign: "justify",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#75c51b",
                    }}
                  >
                    {t("CONTACT")} <br />
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5c5c5c",
                      }}
                    >
                      +91 90995 96003
                      <br />
                      {/* 70434 42881 */}
                      +91 99255 34220
                    </p>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={3}>
                  <EmailIcon style={{ color: "#75c51b", fontSize: "50px" }} />
                  <Typography
                    style={{
                      textAlign: "justify",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#75c51b",
                    }}
                  >
                    {t("MAIL")} <br />
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5c5c5c",
                      }}
                    >
                      {/* info@mrproducts.in */}
                      <a
                        style={{ textDecoration: "none", color: "#5c5c5c" }}
                        href="mailto:info@mrproducts.in"
                      >
                        info@mrproducts.in
                      </a>
                    </p>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "30px", padding: "0 30px" }}
        >
          <iframe
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3705.41682891058!2d72.1452185148674!3d21.764122985604693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f5a7961b230f3%3A0xbd1a2f7aeb8116dd!2sRavitechWorld!5e0!3m2!1sen!2sin!4v1674453532873!5m2!1sen!2sin"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3705.4773740341475!2d72.10255007395777!3d21.76177796220172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f50f9f1d5b581%3A0xfafd6ef171361007!2s366%2F5C%2C%20Chitra%20Gujarat%20Industrial%20Development%20Corporation%2C%20Bhavnagar%2C%20Gujarat%20364004!5e0!3m2!1sen!2sin!4v1732865181901!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </div>
    );
  }
}

export default HoCtToastContainer(withTranslation()(ContactUs));
