import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer";
import Product from "./Components/Product";
import Productinfo from "./Components/Productinfo";
import Distributor from "./Components/Distributor";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import { ToastContainer, Zoom } from "react-toastify";
import Instagram from "./Components/Instagram";
function App() {
  return (
    <HashRouter>
      <div className="MyApp">
        <Navbar />
        <ToastContainer
          position="top-center"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="Product" exact element={<Product />}></Route>
          <Route path="AboutUs" exact element={<AboutUs />}></Route>
          <Route path="Productinfo" exact element={<Productinfo />}></Route>
          <Route path="Distributor" exact element={<Distributor />}></Route>
          <Route path="ContactUs" exact element={<ContactUs />}></Route>
          <Route path="Instagram" exact element={<Instagram />}></Route>

        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
