import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Switch, Menu, MenuItem } from "@mui/material";
import {
  blue,
  deepOrange,
  deepPurple,
  green,
  lightBlue,
  lightGreen,
} from "@mui/material/colors";

const LanguageSwitcher = ({ disabled }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("i18nextLng").length > 0
      ? localStorage.getItem("i18nextLng")
      : "eng"
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const changeLanguage = (lng) => {
    // console.log("lng", lng);
    // localStorage.setItem("selectLanguage", lng);
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    handleClose(); // Close the menu after selecting a language
  };

  const handleChange = (event) => {
    setSelectedLanguage(event.target.checked ? "ગુજ" : "eng");
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Avatar
        onClick={handleAvatarClick}
        sx={{
          bgcolor: disabled ? "#9e9b9b" : "#75c51b",
          color: "white",
          width: 56,
          height: 56,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        {selectedLanguage === "eng" ? "Eng" : "ગુજ"}
      </Avatar>
      {disabled ? (
        ""
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => changeLanguage("eng")}>English</MenuItem>
          <MenuItem onClick={() => changeLanguage("ગુજ")}>ગુજરાતી</MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default LanguageSwitcher;

// // LanguageSwitcher.js
// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { Avatar } from '@material-ui/core';
// import { deepOrange, deepPurple } from '@mui/material/colors';
// import Switch from '@mui/material/Switch';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//   };

//   return (
//     <div>
//       {/* <button onClick={() => changeLanguage('en')}>English</button>
//       <button onClick={() => changeLanguage('gu')}>ગુજરાતી</button> Add Gujarati */}
//      <Switch { <Avatar onClick={() => changeLanguage('en')} sx={{ bgcolor: deepOrange[500] }}>En</Avatar> } />
//      <Switch { <Avatar onClick={() => changeLanguage('gu')}  sx={{ bgcolor: deepPurple[500] }}>Gu</Avatar> } />
//     </div>
//   );
// };

// export default LanguageSwitcher;

// import * as React from 'react';
// import Switch from '@mui/material/Switch';
// import { useTranslation } from 'react-i18next';
// import Avatar from '@mui/material/Avatar';
// import { deepOrange, deepPurple } from '@mui/material/colors';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();
//   const [selectedLanguage, setSelectedLanguage] = React.useState('en');

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//   }

//   const handleChange = (event) => {
//     setSelectedLanguage(event.target.checked ? 'gu' : 'en');
//     };

//   return (
//     <div>
//       <Switch
//         checked={selectedLanguage === 'gu'}
//         onChange={handleChange}
//         inputProps={{ 'aria-label': 'controlled' }}
//       />
//       <Avatar
//         onClick={() => changeLanguage(selectedLanguage)}
//         sx={{ bgcolor: selectedLanguage === 'en' ? deepOrange[500] : deepPurple[500] }}
//       >
//         {selectedLanguage === 'en' ? 'En' : 'Gu'}
//       </Avatar>
//     </div>
//   );
// }

// export default LanguageSwitcher
