import React from "react";
import { Grid, Typography } from "@mui/material";
import "./Footer.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaRegPaperPlane,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

function Footer({ t }) {
  return (
    <div className="footer">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid
          item
          alignItems="center"
          justifyContent="left"
          style={{
            fontSize: "35px",
            color: "#75c51b",
            textAlign: "center", // Ensure the text is centered inside the grid
            padding: "20px", // Optional, to add some spacing around the content
          }}
          xs={12}
          sm={10}
          md={4}
          lg={3}
          xl={3}
        >
          <h2 justifyContent="left">
            {" "}
            {/* Remove default margin for <h2> */}
            <span style={{ color: "#75c51b" }}>{t("MR")}</span>
            <span style={{ color: "white" }}>{t("Products")}</span>
          </h2>
        </Grid>

        <Grid Item xs={12} sm={10} md={4} lg={3} xl={3}></Grid>

        <Grid
          ite
          justifyContent="center"
          alignItems="center"
          style={{
            fontSize: "35px",
            color: "white",
            marginTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
          xs={12}
          sm={10}
          md={4}
          lg={3}
          xl={3}
        >
          <p>{t("Follows On")} : </p>
          <div className="socialmedia">
            <a href="#">
              <FaFacebookSquare fontSize="50px" className="face" />
            </a>
            <a
              href="https://www.instagram.com/mrproducts32?igsh=amptajF4Zmw3Zmw3"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Instagram page"
            >
              <FaInstagramSquare fontSize="50px" className="insta" />
            </a>{" "}
            <a
              href="https://youtube.com/@mr_products123?si=i5CxNX4S_qwMeuCH"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Instagram page"
            >
              <FaYoutubeSquare fontSize="50px" className="you" />
            </a>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-around"
        alignItems="flex-start"
        direction={"row"}
        // spacing={7} // Adjust spacing as needed
        style={{ marginTop: "20px" }}
        paddingLeft={3} // Adjust margin as needed
      >
        <Grid
          item
          xs={12} // Full width on extra small screens
          sm={10} // Half width on small screens
          md={4} // One-third width on medium screens
          lg={3} // One-fourth width on large screens
          xl={3} // One-fourth width on extra-large screens
          direction="row"
        >
          <div
            style={{
              fontSize: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4 className="green">{t("Address")} &nbsp; &nbsp;</h4>
            <h5>
              {t("Plot No. 366/5, Chitra GIDC, Bhavnagar-364004 (Guj. Ind.)")}
            </h5>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </p> */}
          </div>
        </Grid>

        <Grid item xs={12} sm={10} md={4} lg={3} xl={3}>
          <div
            style={{
              fontSize: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>{t("Phone")}</h5>
            <p className="green">+91 90995 96003</p>
            <h5 style={{ marginTop: "40px" }}>{t("Customer Care")}</h5>
            <p className="green">+91 9925534220</p>
            <h5 style={{ marginTop: "40px" }}>{t("Email")}</h5>
            <a
              style={{ fontSize: "26px" }}
              href="mailto:info@mrproducts.in"
              class="green"
            >
              info@mrproducts.in
            </a>
          </div>
        </Grid>

        <Grid item xs={12} sm={10} md={4} lg={3} xl={3}>
          <div
            style={{
              fontSize: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="support">
              <h5>{t("Quick Link")}</h5>
              <ul>
                <li>
                  <Link to="/">{t("Home")}</Link>
                </li>
                <li>
                  <Link to="/Product">{t("Product")}</Link>
                </li>
                <li>
                  <Link to="/AboutUs">{t("About")}</Link>
                </li>
                <li>
                  <Link to="/Distributor">{t("Distributor")}</Link>
                </li>
                <li>
                  <Link to="/ContactUs">{t("Contact")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <div
            style={{
              fontSize: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>Subscribe here</h5>
            <input className="email" type="email" placeholder="Email Address" />
            <button className="submit">
              <FaRegPaperPlane />
            </button>
          </div>
        </Grid> */}
      </Grid>

      <Grid
        container
        marginTop={4}
        justifyContent="space-around"
        alignItems="center"
        textAlign="center"
        style={{
          fontSize: "15px",
          color: "#75c51b",
          backgroundColor: "#2b2b2b",
        }}
      >
        <h2>
          <span style={{ color: "white" }}>{t("footer Text")}</span>
          &nbsp;
          <a
            href="https://ravitechworld.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            RaviTechWorld
          </a>
        </h2>
      </Grid>
    </div>
  );
}

export default withTranslation()(Footer);

// import React from "react";
// import { Grid, Typography } from "@mui/material";
// import "./Footer.css";
// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaYoutubeSquare,
//   FaRegPaperPlane,
// } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { withTranslation } from "react-i18next";

// function Footer({ t }) {
//   return (
//     <div className="footer">
//       <Grid
//         container
//         direction="column"
//         justifyContent="space-around"

//       >
//       <Grid
//         item
//         // justifyContent="left"
//         style={{
//           fontSize: "35px",
//           color: "#75c51b",
//           textAlign: "center", // Ensure the text is centered inside the grid
//           padding: "20px", // Optional, to add some spacing around the content
//         }}
//       >
//         <h2 justifyContent="left"> {/* Remove default margin for <h2> */}
//           <span style={{  color: "#75c51b" }}>{t('MR')}</span>
//           <span style={{ color: "white" }}>{t('Products')}</span>
//         </h2>
//       </Grid>
//       <Grid
//             style={{
//               fontSize: "35px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               textAlign: "center",
//             }}
//           >
//             <h4 className="green">{t('Address')} &nbsp; &nbsp;</h4>
//             <h5>{t('Plot No. 366/5, Chitra GIDC, Bhavnagar-364004 (Guj. Ind.)')}</h5>
//             {/* <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor
//             </p> */}
//           </Grid>

//         <Grid
//           item
//           justifyContent="center"
//           alignItems="center"
//           style={{
//             fontSize: "35px",
//             color: "white",
//             marginTop: "12px",
//             display: "flex",
//             flexDirection: "row",
//           }}
//         >

//         </Grid>

//       <Grid
//         container
//         // justifyContent="space-around"
//         alignItems="center"
//         justifyContent="space-around"
//         direction={'row'}
//         // spacing={7} // Adjust spacing as needed
//         style={{ marginTop: "20px" }}
//         paddingLeft={3} // Adjust margin as needed
//       >
//         <Grid
//           item
//           xs={12} // Full width on extra small screens
//           sm={10} // Half width on small screens
//           md={4} // One-third width on medium screens
//           lg={3} // One-fourth width on large screens
//           xl={3} // One-fourth width on extra-large screens
//         >

//         </Grid>

//         <Grid item xs={12} sm={10} md={4} lg={3} xl={3}>
//           <div
//             style={{
//               fontSize: "35px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <h5>{t('Phone')}</h5>
//             <p className="green">+91 90995 96003</p>
//             <h5 style={{ marginTop: "40px" }}>{t('Customer Care')}</h5>
//             <p className="green">+91 9925534220</p>
//             <h5 style={{ marginTop: "40px" }}>{t('Email')}</h5>
//             <a
//               style={{ fontSize: "26px" }}
//               href="mailto:info@mrproducts.in"
//               class="green"
//             >
//               info@mrproducts.in
//             </a>
//           </div>
//         </Grid>

//         <Grid item xs={12} sm={10} md={4} lg={3} xl={3}>
//           <div
//             style={{
//               fontSize: "35px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               textAlign: "center",
//             }}
//           >
//                       <p>{t('Follows On')} : </p>
//           <div className="socialmedia">
//             <a href="#">
//               <FaFacebookSquare fontSize="50px" className="face" />
//             </a>
//             <a href="#">
//               <FaInstagramSquare fontSize="50px" className="insta" />
//             </a>
//             <a href="#">
//               <FaYoutubeSquare fontSize="50px" className="you" />
//             </a>
//           </div>
//             <div className="support">
//               <h5>{t('Quick Link')}</h5>
//               <ul>
//                 <li>
//                   <Link to="/">{t('Home')}</Link>
//                 </li>
//                 <li>
//                   <Link to="/Product">{t('Product')}</Link>
//                 </li>
//                 <li>
//                   <Link to="/AboutUs">{t('About')}</Link>
//                 </li>
//                 <li>
//                   <Link to="/Distributor">{t('Distributor')}</Link>
//                 </li>
//                 <li>
//                   <Link to="/ContactUs">{t('Contact')}</Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </Grid>

//         {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
//           <div
//             style={{
//               fontSize: "35px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <h5>Subscribe here</h5>
//             <input className="email" type="email" placeholder="Email Address" />
//             <button className="submit">
//               <FaRegPaperPlane />
//             </button>
//           </div>
//         </Grid> */}
//       </Grid>

//       <Grid
//         container
//         marginTop={4}
//         justifyContent="space-around"
//         alignItems="center"
//         textAlign="center"
//         style={{
//           fontSize: "15px",
//           color: "#75c51b",
//           backgroundColor: "#2b2b2b",
//         }}
//       >
//         <h2>
//           <span style={{ color: "white" }}>
//            {t('© 2023 RaviTechWorld. All rights reserved. Developed by&nbsp;')}
//           </span>
//           &nbsp;<a href="https://ravitechworld.com/" target="_blank" rel="noopener noreferrer">
//          RaviTechWorld
//           </a>
//         </h2>
//       </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default withTranslation()(Footer);
