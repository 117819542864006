import { CardMedia, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import Image2 from "./images/bg1.jpg";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Link } from "react-router-dom";
import { withRouter } from "../HOC/withRouter";
import { apiURL } from "./API";

class Productinfo extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.onFormLoad();
  }

  // onFormLoad = () => {
  //   console.log("this.props.location", this.props.location.state);
  // };

  render() {
    const span = {
      color: "#006838",
    };
    // console.log(
    //   "this.props.location.state.homepage",
    //   this.props.location.state.homepage
    // );
    // console.log(
    //   "this.props.location.state.vac_producttitle",
    //   this.props.location.state.vac_producttitle
    // );
    return (
      <div>
        <CardMedia
          sx={{ height: "300px", marginTop: "-115px" }}
          src={Image2}
          component="img"
          alt="banner"
        />

        <Typography
          variant="h1"
          style={{
            fontSize: "30px",
            color: "black",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
          }}
          marginTop={3}
        >
          <Link to={this.props.location.state.homepage ? "/" : "/Product"}>
            <ArrowCircleLeftIcon
              style={{
                fontSize: "40px",
                color: "#75c51b",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
          </Link>
          {/* <h3>Back To Products</h3> */}
          <h3>
            {this.props.location.state.homepage
              ? "Back To Home"
              : "Back To Products"}
          </h3>
        </Typography>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          marginBottom={3}
        >
          <Grid item>
            <Typography fontSize={35} color={"#75c418"}>
              <h3>Product Information</h3>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
          direction="row"
          style={{ backgroundColor: "#52d4f82c" }}
        >
          <Grid item xs={10} md={4}>
            <CardMedia
              sx={{ height: "400px", width: "300px" }}
              // src={apiURL + this.props.location.state.vac_productimage}
              src={
                this.props.location.state.vac_productimage.includes(apiURL)
                  ? this.props.location.state.vac_productimage
                  : apiURL + this.props.location.state.vac_productimage
              }
              component="img"
              alt="banner"
            />
            <Typography fontSize={20} color={"black"}>
              <h3>
                <span style={span}>MRP : </span>
                {this.props.location.state.dec_productmrp}
              </h3>
              {/* this.props.location.state.dec_productmrp */}
            </Typography>
            <Typography fontSize={20} color={"black"}>
              <h3>
                <span style={span}>Net Weight : </span>
                {this.props.location.state.vac_netweight}
              </h3>
            </Typography>
          </Grid>
          <Grid item xs={10} md={6}>
            <Typography fontSize={30} color={"black"}>
              <h3>
                <span style={span}>Product Title : </span>
                {this.props.location.state.vac_producttitle}
              </h3>
            </Typography>
            <Typography fontSize={26} color={"black"}>
              <h3>
                <span style={span}>Product Sub Title : </span>
                {this.props.location.state.vac_productsubtitle}
              </h3>
            </Typography>
            <Typography fontSize={24} color={"black"}>
              <h3>
                <span style={span}>Product Category : </span>
                {this.props.location.state.vac_productcategory}
              </h3>
            </Typography>
            <Typography
              fontSize={22}
              color={"black"}
              style={{ textAlign: "justify", wordWrap: "break-word" }}
            >
              <h3>
                <span style={span}>Product Details : </span>
                {this.props.location.state.vac_productdetails}
              </h3>
            </Typography>
            <Typography
              fontSize={22}
              color={"black"}
              style={{ textAlign: "justify", wordWrap: "break-word" }}
            >
              <h3>
                <span style={span}>Product Dose Details : </span>
                {this.props.location.state.vac_productdosedetails}
              </h3>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Productinfo);
