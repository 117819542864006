import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import your translation files
import translationEN from "./locales/en/translation.json";
import translationGU from "./locales/ગુજ/translation.json";

// Initialize i18next
i18n
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    debug: true,
    lng:
      localStorage.getItem("i18nextLng") &&
      localStorage.getItem("i18nextLng").length > 0
        ? localStorage.getItem("i18nextLng")
        : "eng",
    resources: {
      en: { translation: translationEN },
      ગુજ: { translation: translationGU },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
