import { CardMedia } from "@mui/material";
import React, { Component } from "react";
import imgBanner2 from "../images/bg2.jpg";
import "./Banner.css";

class Banner extends Component {
  render() {
    return (
      <div>
        <div className="imgOfhomePage">
          <CardMedia
            sx={{ height: "auto", marginTop: "-115px" }}
            src={imgBanner2}
            component="img"
            alt="banner"
          />
        </div>
      </div>
    );
  }
}
export default Banner;
