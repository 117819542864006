import React, { useEffect } from "react";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import a1jpg from "../images/a1.jpg";
import a2jpg from "../images/a2.jpg";
import a3jpg from "../images/a3.jpg";
import a4jpg from "../images/a4.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { withTranslation } from "react-i18next"; // Import withTranslation

const Ourinfo = ({ t }) => {
  // Destructure t from props
  useEffect(() => {
    Aos.init({
      duration: 2000, // Set the animation duration
    });
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={2}
        marginTop={6}
        justifyContent="space-evenly"
        marginBottom={4}
      >
        <Grid item xs={10} md={5}>
          <Typography
            variant="h4"
            style={{ fontSize: "20px", color: "#70bd21" }}
          >
            {t("ABOUT US")}
          </Typography>
          <Typography variant="h5" style={{ fontSize: "30px", color: "black" }}>
            <b>{t("Agriculture & Organic Product ")}</b>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontSize: "18px", color: "#a3acab", textAlign: "justify" }}
          >
            {t("Hello")}

            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud ullamco laboris. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Cum eum voluptate laborum quidem
            distinctio, eligendi quam nostrum, est, excepturi ex beatae adipisci
            dicta delectus quo ea aliquid? Est nostrum, dolores ex error at
            voluptatum? */}
          </Typography>
        </Grid>
        <Grid item xs={10} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <CardMedia
                data-aos="fade-up"
                sx={{ height: "400px" }}
                src={a1jpg}
                component="img"
                alt="banner"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <CardMedia
                    data-aos="fade-down"
                    sx={{ height: "200px" }}
                    src={a2jpg}
                    component="img"
                    alt="banner"
                  />
                </Grid>
                <Grid item>
                  <CardMedia
                    data-aos="fade-right"
                    sx={{ height: "200px" }}
                    src={a3jpg}
                    component="img"
                    alt="banner"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(Ourinfo); // Wrap with withTranslation
